import axios from "axios";

export default axios.create({
  baseURL: "https://api.southteams.io/v1/",
  // baseURL: "http://localhost:8080",
  responseType: "json",
  headers: {
    Authorization: window.localStorage.getItem("token"),
  },
});
