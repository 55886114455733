import React, { useEffect, useState } from "react";
import { Modal, Button, Header } from "semantic-ui-react";

const InvalidModal = ({ open, onMarkAsNotValid, onClose }) => {
  const [removeReason, setRemoveReason] = useState();

  return (
    <Modal onClose={onClose} open={true} dimmer="blurring">
      <Modal.Header>Mark Prospect as Not Valid</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>Why do you think this prospect is not valid?</Header>
          <textarea
            style={{
              width: "100%",
              height: 200,
              padding: 10,
              resize: "none",
              border: "solid 1px #ebebeb",
            }}
            placeholder="Enter a reason for removing this prospect..."
            onChange={(e) => setRemoveReason(e.target.value)}
          ></textarea>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Ugh... let me check again</Button>
        <Button
          content="Yep, mark it as not valid"
          icon="delete"
          onClick={() => onMarkAsNotValid(removeReason)}
          negative
          disabled={!(removeReason && removeReason.length > 10)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default InvalidModal;
