import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Label } from "semantic-ui-react";
import "./navMenu.css";
import { useUser, useSessionActions } from "../session/hooks";
import api from "../../store/api";

const NavMenu = () => {
  const user = useUser();
  const { logOut } = useSessionActions();

  const [data, setData] = useState();

  useEffect(async () => {
    try {
      const res = await api.get("/cold-email/my-numbers");
      setData(res.data);
    } catch (e) {}
  }, []);

  return (
    <Menu borderless size="small" className="navMenu">
      <Menu.Item href="/">
        <img src="/img/48.png" alt="Southteams" style={{ marginLeft: 20 }} />
      </Menu.Item>

      {/* <Menu.Item name="dashboard" href="/" className="navMenuItem">
        Dashboard
      </Menu.Item> */}

      {!data && <Menu.Item item>Loading numbers...</Menu.Item>}

      {data && (
        <React.Fragment>
          <Menu.Item item>
            <span>Today Total:</span>&nbsp;
            <b>{data.today + data.todayInvalids}</b>&nbsp; - (Lines:&nbsp;
            <b>{data.today}</b>
            &nbsp;-&nbsp;Invalid:&nbsp;<b>{data.todayInvalids}</b>)
          </Menu.Item>

          <Menu.Item item>
            <span>This Month Total:</span>&nbsp;
            <b>{data.month + data.monthInvalids}</b>&nbsp; - (Lines:&nbsp;
            <b>{data.month}</b>
            &nbsp;-&nbsp;Invalid:&nbsp;<b>{data.monthInvalids}</b>)
          </Menu.Item>
        </React.Fragment>
      )}

      <Dropdown item text={user.name} className="right">
        <Dropdown.Menu>
          <Menu.Item as="a" href="/vienna-chrome-ext.zip">
            Download Chrome Extension
          </Menu.Item>
          <Menu.Item name="logout" onClick={logOut}>
            Log Out
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};

export default NavMenu;
