import React, {
  useState,
  useEffect,
  isValidElement,
  useDebugValue,
} from "react";
import TabMenu from "./tabMenu";
import InvalidModal from "./invalidModal";
import ConfirmModal from "./confirmModal";
import api from "../../store/api";

import { Button, Confirm, Modal, Header } from "semantic-ui-react";
import "./emailForm.css";

// {
//   firstName: "Vittorio Luigi Bertocci",
//   linkedin: "https://www.linkedin.com/in/vittoriobertocci/",
//   company: "Auth0",
//   companyDomain: "auth0.com",
//   position: "Principal Architect",
// }

const EmailForm = () => {
  const [prospect, setProspect] = useState();

  const [removeReason, setRemoveReason] = useState();
  const [line, setLine] = useState();

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successNotValid, setSuccessNotValid] = useState(false);

  const [openConfirm, setOpenConfirm] = useState();
  const [openInvalidModal, setOpenInvalidModal] = useState(false);

  useEffect(async () => {
    try {
      const res = await api.get("/cold-email/new-prospect");

      setProspect(res.data);
      setLoaded(true);
    } catch (e) {
      setError(e);
    }
  }, []);

  const sendEmail = async () => {
    try {
      setSaving(true);

      const res = await api.post("/cold-email/add-line", {
        id: prospect.id,
        line: line,
      });

      setSuccess(true);
    } catch (e) {
      setError(e);
    }

    setSaving(false);
    setOpenConfirm(false);
  };

  const markAsNotValid = async (reason) => {
    try {
      setSaving(true);
      const res = await api.post("/cold-email/invalid-prospect", {
        id: prospect.id,
        removeReason: reason,
      });
      setSaving(false);
      setSuccessNotValid(true);
    } catch (e) {
      setError(e);
    }

    setOpenInvalidModal(false);
  };

  if (error) {
    return (
      <div>
        <h2>Error!...</h2>
        <p>{JSON.stringify(error.response.data)}</p>
      </div>
    );
  }

  if (saving) {
    return <h2>Saving...</h2>;
  }

  if (!loaded) {
    return <h2>Finding a new prospect...</h2>;
  }

  if (success || successNotValid) {
    return (
      <section
        style={{ textAlign: "center" }}
        onKeyPress={(e) => alert(JSON.stringify(e))}
      >
        <div>
          {success && <h1>Message sent!</h1>}
          {successNotValid && <h1>Prospect marked as not valid!</h1>}
          <p>
            Click &nbsp;
            <Button
              className="ui button green"
              onClick={() => window.location.reload()}
            >
              Here
            </Button>{" "}
            to send a new one!
          </p>
        </div>
      </section>
    );
  }

  return (
    <div className="row">
      <div id="col1">
        <h2 class="ui header">Send a New Message</h2>

        <table style={{ width: "100%" }}>
          <tr>
            <td colSpan="3">
              <p style={{ fontSize: "1.2em", marginBottom: 5 }}>
                <b>{prospect.firstName}</b>
              </p>
              <p>
                {prospect.position} at {prospect.company} - ({prospect.type})
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <a href={"https://" + prospect.companyDomain} target="_blank">
                <i className="icon world" />
                Website
              </a>
            </td>

            <td>
              <a href={prospect.linkedin} target="_blank">
                <i className="icon linkedin" />
                LinkedIn
              </a>
            </td>
            <td>
              <a
                href={"https://www.google.com/search?&q=" + prospect.company}
                target="_blank"
              >
                <i className="icon google" />
                Search
              </a>
            </td>
          </tr>
        </table>

        { prospect.dateAdded && (
        <div className="envelope">
          Added on <b>{new Date(prospect.dateAdded).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</b>
        </div>
        )}
        
        <div className="envelope">
          <b>Prospecter Note: </b>{ prospect.info || "No note."}
        </div>
        
        <div className="envelope">
          <p>
            <i>Hi {prospect.firstName.split(" ")[0]},</i>
          </p>

          <p>
            <i>I hope you are doing well.</i>
          </p>
          <textarea
            placeholder="Your line here..."
            sizable="false"
            onChange={(e) => setLine(e.target.value)}
            maxLength="250"
          ></textarea>

          <p>
            <i>Quick question, have you ....</i>
          </p>

          <p>
            <i>Thanks,</i>
          </p>
        </div>
        <div style={{ marginTop: 25 }}>
          <Button
            positive
            style={{ float: "left" }}
            disabled={!(line && line.length > 20)}
            onClick={() => setOpenConfirm(true)}
          >
            Send now!
          </Button>

          <Button
            negative
            style={{ float: "right" }}
            onClick={() => setOpenInvalidModal(true)}
          >
            Mark as not valid
          </Button>
        </div>
      </div>
      <div id="col2">
        <TabMenu prospect={prospect} />
      </div>

      {openConfirm && (
        <ConfirmModal
          onClose={() => setOpenConfirm(false)}
          onConfirm={sendEmail}
          prospect={prospect}
          line={line}
        />
      )}

      {openInvalidModal && (
        <InvalidModal
          onMarkAsNotValid={markAsNotValid}
          onClose={() => setOpenInvalidModal(false)}
        />
      )}
    </div>
  );
};

export default EmailForm;
