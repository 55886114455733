import React, { useState, useEffect, isValidElement } from "react";
import "../browser/browser.css";

const Browser = ({ url }) => {
  const [addressBar, setAddressBar] = useState(url);
  const [location, setLocation] = useState(url);
  const [loaded, setLoaded] = useState(false);

  const [command, setCommand] = useState();
  const navigate = () => {
    setLoaded(false);
    setLocation("");
    setLocation(addressBar);
  };

  const onLoad = (e) => {
    setLoaded(true);

    // alert(window.frames[0].src);
  };

  return (
    <div class="browser">
      {/* <div className="toolbar">
        <button onClick={() => window.history.back()}>
          <i class="arrow left icon"></i>
        </button>

        <button>
          <i class="arrow right icon"></i>
        </button>

        {loaded && (
          <button onClick={navigate}>
            <i class="refresh icon"></i>
          </button>
        )}

        {!loaded && (
          <button onClick="navframe.stop">
            <i class="cancel icon"></i>
          </button>
        )}

        <input
          disabled={!loaded}
          type="text"
          value={addressBar}
          onChange={(e) => setAddressBar(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && navigate()}
        />
      </div> */}

      <iframe name="navframe" src={location} onLoad={onLoad} />
    </div>
  );
};

export default Browser;
