import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import EmailForm from "./components/emailForm/emailForm";
import NavMenu from "./components/navMenu/navMenu";
import Dashboard from "./components/dashboard/dashboard";

const App = () => (
  <React.Fragment>
    <NavMenu />
    <div style={{ padding: 25 }}>
      <Switch>
        {/* <Route path="/" exact component={Dashboard} /> */}
        <Route path="/" exact component={EmailForm} />

        {/* <Route exact path="/candidate/:candidateId" component={Candidate} /> */}
      </Switch>
    </div>
  </React.Fragment>
);

export default App;
