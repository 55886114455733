import React, { useEffect, useState } from "react";
import { Modal, Button } from "semantic-ui-react";

const ConfirmModal = ({ onConfirm, onClose, prospect, line }) => {
  const [seconds, setSeconds] = useState(10);
  const [disableSend, setDisableSend] = useState(true);

  useEffect(() => {
    let times = 0;
    const id = setInterval(() => {
      times = times + 1;

      setSeconds(10 - times);
      if (times === 10) {
        clearInterval(id);
        setDisableSend(false);
      }
    }, 1000);
  }, []);

  return (
    <Modal onClose={onClose} open="true" dimmer="blurring">
      <Modal.Header>
        Take 10 seconds to review your line, this is the last chance:
      </Modal.Header>
      <Modal.Content>
        <table>
          <tr>
            <td>
              <b>To: </b>
              {prospect.firstName} ({prospect.position} at {prospect.company})
            </td>
          </tr>
          <tr>
            <td>
              <p>Hi {prospect.firstName.split(" ")[0]},</p>
              <p>
                I hope you are doing well. <b>{line}</b>
              </p>
              <p>Quick question, have you...</p>
              <p>Thanks,</p>
            </td>
          </tr>
        </table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Ugh... let me fix something</Button>
        <Button
          content={disableSend ? seconds : "Yep, send it!"}
          icon="check"
          onClick={onConfirm}
          positive
          disabled={disableSend}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;
