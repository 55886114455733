import React, { useState, useEffect, isValidElement } from "react";
import Browser from "../browser/browser";
import { Menu } from "semantic-ui-react";

const TabMenu = ({ prospect }) => {
  const [tab, setTab] = useState("linkedin");

  return (
    <React.Fragment>
      <Menu tabular style={{ marginBottom: -1 }}>
        <Menu.Item
          active={tab === "linkedin"}
          onClick={() => setTab("linkedin")}
        >
          <i className="icon linkedin" />
          LinkedIn Profile
        </Menu.Item>

        <Menu.Item
          active={tab === "linkedin-feed"}
          onClick={() => setTab("linkedin-feed")}
        >
          <i className="icon linkedin" />
          LinkedIn Activity
        </Menu.Item>

        <Menu.Item
          name="Company Website"
          active={tab === "website"}
          onClick={() => setTab("website")}
          icon="world"
        />

        <Menu.Item
          active={tab === "crunchbase"}
          onClick={() => setTab("crunchbase")}
        >
          <i className="icon copyright outline" />
          CrunchBase Search
        </Menu.Item>

        <Menu.Item
          name="Google Search"
          active={tab === "google"}
          onClick={() => setTab("google")}
          icon="google"
        />
      </Menu>

      {tab === "linkedin" && <Browser url={prospect.linkedin} />}

      {tab === "linkedin-feed" && (
        <Browser url={prospect.linkedin + "/detail/recent-activity"} />
      )}

      {tab === "website" && (
        <Browser url={"https://" + prospect.companyDomain} />
      )}

      {tab === "crunchbase" && (
        <Browser
          url={"https://www.crunchbase.com/textsearch?q=" + prospect.company}
        />
      )}

      {tab === "google" && (
        <Browser url={"https://www.google.com/search?&q=" + prospect.company} />
      )}
    </React.Fragment>
  );
};

export default TabMenu;
